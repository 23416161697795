body {
  overflow-x: hidden;
  left: 0;
  right: 0;
  margin: 0;
  min-height: 100%;
  font-family: 'Gilroy';
  background-color: #1D1D1D;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.weather-widget {
  background-image: linear-gradient(
    45deg, #030303, #282626);
  border: 1px solid #272727;
  border-radius: 10px;
  padding: 18px 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  box-shadow: 0px 0px 50px -3px rgba(0,0,0,0.46);
  margin: 30px;
}

h1 {
  display: inline-block;
}

p {
  font-weight: 600;
}

.weather-widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.widget-city-name {
  background-color: #252223;
  border: 2px solid #272727;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 3px 12px;
  border-radius: 4px;
  height: 20px;
  font-size: 14px;
}

.weather-widget-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
}

.weather-image-icon {
  width: 50%;
}

.weather-widget-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.weather-type {
  color: #6d6d6d;
  font-weight: 600;
}

.widget-data-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
}

.widget-data-col p {
  font-size: 15px;
}

.little-weather-icon {
  width: 20px;
  margin-right: 25px;
}

.temperature {
  margin: 0px;
  font-weight: 400;
  font-size: 60px;
}

.widget-cards-collection {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}